import Image from 'next/image';
import NextLink from 'next/link';
import { Page } from 'features/app/types';
import AppPadding from '@/sci-ui-components/AppPadding/AppPadding';
import { Route } from '@/features/app/constants';
import Button from '@/sci-ui-components/buttons/Button/Button';

const NotFoundPage: Page = () => {
  return (
    <div className="twp h-screen w-screen flex items-center justify-center relative z-[999]">
      <AppPadding>
        <div className="relative bg-gray-200 p-[2.625rem] flex flex-col items-center justify-center z-[900]">
          <h1 className="text-[4rem] font-extrabold mb-0">OOPS!</h1>
          <Image src="/images/404-tpott.png" width="354" height="215" alt="T-Pott 404 Not Found" className="mb-4" />
          <p className="max-w-[500px] text-center">
            It looks like the system encountered an error. We&apos;ll look into it - but in the meantime, you can reload
            Market Movers{' '}
            <NextLink href={Route.Dashboard} className="text-brand font-bold">
              here
            </NextLink>
            .
          </p>
          <Button href={Route.Dashboard} className="bg-brand text-white borderRadius-lg px-4 py-2">
            RELOAD MARKET MOVERS
          </Button>
        </div>
      </AppPadding>
      <Image
        src="/images/market-movers-wave-ornament.png"
        width="525"
        height="562"
        alt="Market Movers Wave Ornament"
        className="absolute bottom-0 right-0 z-[1]"
      />
    </div>
  );
};

NotFoundPage.shell = 'minimal';

export default NotFoundPage;
